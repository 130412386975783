<template>
  <h1>Our story</h1>
  <div
    v-for="(con, i) in content.content"
    :key="con.id"
    class="relative-parent"
  >
    <div
      class="delete-row"
      style="z-index: 1"
      @click="
        () => {
          content.content.splice(i, 1);
        }
      "
    >
      <svg
        class="svg-inline--fa fa-trash-alt fa-w-14"
        data-v-b4ae8fbc=""
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="trash-alt"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
        ></path>
      </svg>
    </div>
    <div class="mb-2 row" style="padding: 5px; border: 1px solid black">
      <div class="col-6">
        <label>Headline</label>
        <el-input v-model="con.headline" placeholder="Please input" />
      </div>
      <div class="col-6">
        <label>Description</label>
        <el-input v-model="con.subHeadline" placeholder="Please input" />
      </div>
      <div class="col-6">
        <label class="mb-2">Image</label>
        <ImageSelectorInput
          v-model="con.image"
          :multiple="false"
          :featuredImageSlug="con.image ? con.image : 'empty'"
          :returnSlug="true"
        ></ImageSelectorInput>
      </div>
      <div class="col-6">
        <label>Button</label>
        <div class="col">
          <div class="col">
            <el-select
              v-model="con.button.type"
              @change="() => clearLinkInput(i)"
              class="mt-2"
              placeholder="Button type"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            /></el-select>
            <div class="mt-2">
              <el-select
                v-if="con.button.type == 'action'"
                v-model="con.button.link"
                placeholder="Link type"
              >
                <el-option
                  v-for="item in actionsList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              /></el-select>
            </div>
            <div class="mt-2" v-if="con.button.type == 'internal-page'">
              <el-select
                v-model="con.button.link"
                filterable
                remote
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                v-on:focus="() => remoteMethod('')"
                :loading="loading"
                value-key="id"
              >
                <el-option
                  v-for="item in data"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                />
              </el-select>
            </div>
            <div class="mt-2" v-if="con.button.type === 'filter'">
              <el-select
                v-model="con.button.filterType"
                placeholder="Choose filter type"
                value-key="id"
              >
                <el-option
                  v-for="item in filterTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <div class="mt-2" v-if="con.button.filterType">
                <el-select
                  v-model="con.button.filterValue"
                  filterable
                  remote
                  :placeholder="'Search ' + con.button.filterType"
                  :remote-method="(query) => remoteMethod(query, con.button.filterType)"
                  v-on:focus="() => remoteMethod('', con.button.filterType)"
                  :loading="loading"
                  value-key="id"
                >
                  <el-option
                    v-for="item in artistsData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div class="col">
            <el-input
              class="mt-2"
              v-model="con.button.label"
              placeholder="Please enter button label"
            />
            <el-input
              class="mt-2"
              v-if="con.button.type == 'link'"
              v-model="con.button.link"
              placeholder="Please enter link"
            />
            <el-checkbox
              class="mt-2"
              v-if="con.button.type != 'action'"
              v-model="con.button.targetBlank"
              true-value="1"
              false-value="0"
              >Open in new tab?</el-checkbox
            >
          </div>
        </div>
      </div>
      <div class="col-6 mb-2">
        <label>Row reversed?</label>
        <br />
        <el-checkbox
          v-model="con.rowReversed"
          label="Row reversed"
          size="large"
        />
      </div>
    </div>
  </div>
  <el-button @click="addNewRow" class="mt-2" size="small" type="primary"
    >Add new row</el-button
  >
  <el-button
    @click="deleteBlock(blockData.id)"
    class="mt-2"
    size="small"
    type="danger"
    >Delete this block</el-button
  >
</template>

<script>
import ImageSelectorInput from "../../Tiptap/ImageSelectorInput.vue";

const FILTER_TYPE_ARTIST = 'artist';

export default {
  name: "OurStory",
  props: {
    blockData: Object,
  },
  components: {
    ImageSelectorInput,
  },
  data() {
    return {
      options: [
        {
          value: "link",
          label: "External Link",
        },
        {
          value: "action",
          label: "Action",
        },
        {
          value: "internal-page",
          label: "Internal page",
        },
        {
          value: "filter",
          label: "Search results page",
        },
      ],
      filterTypes: [
        {
          value: FILTER_TYPE_ARTIST,
          label: "By artist",
        },
      ],
      content: {
        content: [
          {
            id: 0,
            headline: "",
            subHeadline: "",
            rowReversed: false,
            image: "",
            button: {
              type: "",
              label: "",
              link: "",
              targetBlank: false,
            },
          },
        ],
      },
      actionsList: [
        {
          value: "focusScreen",
          label: "Focus Modal",
        },
      ],
      data: [],
      artistsData: [],
      loading: false,
    };
  },
  methods: {
    addNewRow() {
      this.content.content.push({
        id:
          this.content.content.length > 0
            ? this.content.content[this.content.content.length - 1].id + 1
            : 0,
        headline: "",
        subHeadline: "",
        rowReversed: false,
        button: {
          type: "",
          label: "",
          link: "",
          targetBlank: false,
        },
      });
    },
    deleteBlock(id) {
      this.$store.dispatch("removeBlockFromList", id);
    },
    clearLinkInput(i) {
      this.content.content[i].button.link = "";
    },
    remoteMethod: function (query, type = 'pages') {
      if (query !== undefined) {
        this.loading = true;
        this.data = [];
        const url = this.getApiUrlFromType(type);
        this.$api
          .get(
            `${
              process.env.VUE_APP_SHARED_API
            }${url}?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              this.pushSearchResults(res.data.data, type);
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.data = [];
      }
    },
    pushSearchResults(array, type) {
      array.forEach(item => {
        if (type === FILTER_TYPE_ARTIST) {
          if (! this.artistsData.find(artist => artist.id === item.id)) {
            this.artistsData.push({
              id: item.id,
              label: item.name || item.title,
              value: item,
            });
          }
        } else {
          if (! this.data.find(d => d.id === item.id)) {
            this.data.push({
              id: item.id,
              label: item.name || item.title,
              value: item,
            });
          }
        }
      })
    },
    getApiUrlFromType: function (type) {
      switch (type) {
        case FILTER_TYPE_ARTIST:
          return 'api/artists';
        default:
          return 'api/page-translations';
      }
    },
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_BLOCK_CONTENT") {
        this.$store.dispatch("updateBlockContent", {
          blockData: this.blockData,
          blockContent: this.content.content,
        });
      }
    });
    if (this.blockData.created_at) {
      if (this.blockData.content[0]) {
        this.blockData.content.forEach(item => {
          if (item.button.type === 'filter' && item.button.filterValue && item.button.filterType) {
            if (item.button.filterType === FILTER_TYPE_ARTIST) {
              this.artistsData.push({
                id: item.button.filterValue.id,
                label: item.button.filterValue.name,
                value: item.button.filterValue
              });
            }
            // Add other if necessary in the future
          } else if (item.button.type === 'internal-page') {
            this.data.push(item.button.link);
          }
        });
      }
      this.content.content = this.blockData.content;
    }
  },
};
</script>

<style scoped>
.delete-row {
  position: absolute;
  top: -5px;
  right: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  align-items: center;
}
.delete-row > svg {
  color: white;
  fill: white;
}
.relative-parent {
  position: relative;
}
</style>
